var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[(_vm.charts)?_c('h1',{staticClass:"text-subtitle-2"},[_vm._v(" This project is empty, run a simulation to view results here. ")]):_vm._e(),_vm._l((_vm.charts),function(production){return [(
        production.type == 'movie' ||
          production.type == 'flux' ||
          production.type == 'graphic'
      )?_c('v-col',{key:production.id,attrs:{"lg":"4","xl":"3","cols":"12"}},[(production.type == 'movie')?_c('v-card',[_c('v-card-title',[_c('h1',{staticClass:"text-h5"},[_vm._v(_vm._s(production.title))]),_c('h2',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(Date(production.create_date))+" ")])]),_c('v-card-text',[_c('video',{attrs:{"width":"100%","controls":"","src":production.content}})])],1):_vm._e(),(production.type == 'flux')?_c('v-card',[_c('v-card-text',[_c('Plotly',{staticStyle:{"width":"100%","padding-bottom":"100%"},attrs:{"data":[
              {
                x: _vm.charts[production._id].row1.x,
                y: _vm.charts[production._id].row1.y,
                type: 'scatter'
              },
              {
                x: _vm.charts[production._id].row2.x,
                y: _vm.charts[production._id].row2.y,
                type: 'scatter'
              },
              {
                x: _vm.charts[production._id].row3.x,
                y: _vm.charts[production._id].row3.y,
                type: 'scatter'
              }
            ],"options":{ responsive: true },"layout":{
              ..._vm.layout,
              title: production.title,
              responsive: true
            },"display-responsive":true,"responsive":true,"options-responsive":true}}),_c('p',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(Date(production.create_date))+" ")])],1)],1):_vm._e(),(production.type == 'graphic')?_c('v-card',[_c('v-card-text',[_c('Plotly',{staticClass:"PlotlyChart",staticStyle:{"width":"100%","padding-bottom":"100%"},attrs:{"data":[
              {
                title: 'watermark_1',
                z: _vm.charts[production._id],
                colorscale: _vm.colorScale(production),
                showscale: false,
                type: 'heatmap'
              }
            ],"options":{ responsive: true },"layout":{
              name: '',
              ..._vm.layout,
              title: production.title,
              responsive: true,
              type: 'rect'
            },"display-responsive":true,"responsive":true,"options-responsive":true}}),_c('p',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(Date(production.create_date))+" ")])],1)],1):_vm._e()],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }